import { tracer } from '@/otel';
import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/vue';
import type { OpenApiClient, OpenApiClientOptions } from '@signal24/openapi-client-codegen/browser';
import { configureVfOpenApiClient } from '@signal24/vue-foundation';

export function configureZynoOpenApiClient(client: OpenApiClient, options?: OpenApiClientOptions) {
    configureVfOpenApiClient(client, {
        ...options,

        wrapper(options, fn) {
            return new Promise((resolve, reject) => {
                return tracer.startActiveSpan('api', span => {
                    const promise = fn(options);
                    promise
                        .then(resolve)
                        .catch(reject)
                        .finally(() => span.end());
                });
            });
        },

        onError(err) {
            Sentry.withScope(scope => {
                const spanCtx = trace.getActiveSpan()?.spanContext();
                if (spanCtx) {
                    scope.setContext('trace', {
                        trace_id: spanCtx.traceId,
                        span_id: spanCtx.spanId
                    });
                }
                Sentry.captureException(err);
            });
        }
    });
}
