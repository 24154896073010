import * as Sentry from '@sentry/vue';
import { configureVf, createFilters, installVf } from '@signal24/vue-foundation';
import currency from 'currency.js';
import { addDays, format, parse } from 'date-fns';
import type { App } from 'vue';

import type { hasPermission } from './shared/helpers/permissions.helpers';

// configure Vue Foundation
configureVf({
    defaultDateFormat: 'M/d/yy',
    defaultTimeFormat: 'h:mmaaa',

    errorHandler(err) {
        console.error(err);
        Sentry.captureException(err);
    }
});

// create base filters + any custom filters
export const filters = createFilters(() => ({
    usCurrency: (value: number, divisor?: number) => {
        return currency(value)
            .divide(divisor ?? 100)
            .format();
    },

    divide: (value: number, divisor: number) => {
        return currency(value).divide(divisor).value;
    },

    oneDayForward: (date?: string | null) => {
        if (!date) return date;
        return format(addDays(parse(date, 'yyyy-MM-dd', new Date()), 1), 'yyyy-MM-dd');
    }
}));

// attach filters to Vue
declare module 'vue' {
    export interface ComponentCustomProperties {
        $filters: typeof filters;
        $hp: typeof hasPermission;
    }
}

// global Vue Foundation setup helper
export function setupVf(app: App, injectables?: { $hp: typeof hasPermission }) {
    installVf(app);
    app.config.globalProperties.$filters = filters;

    if (injectables) {
        app.config.globalProperties.$hp = injectables.$hp;
    }
}
